body {
    text-align: center;
}

.card {
    box-shadow: 0rem .5rem 1rem rgba(0, 0, 0, .3);
    background-color: #fff;
    border-radius: 0.3rem;
    transition: all .2s ease-in-out;
    transform: scale(0.95);
    width: 100px;
    height: 150px;
    margin: 20px;
    font-size: 12px;
}

.card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 1rem 2rem rgba(0, 0, 0, 0.2);
    transform: scale(1);
    cursor: pointer;
}

.title_1 {
    font-family: 'Montserrat', sans-serif;
    background-color: goldenrod;
    padding: .5rem 0;
    border-radius: 0.3rem 0.3rem 0 0;
    font-size: 14px;
}

.pic {
    max-width: 100%;
}

.content {
    padding: .2rem;
}

.small {
    max-width: 15rem;
}